import React from 'react';
import { graphql } from 'gatsby';
import Layout, { Content } from './components/Layout';
import formatSlug from './lib/slugFormatter';
import HeroHeader from './components/heroheader';
import {
  Container as BlogContainer,
  Entry as BlogEntry,
} from './components/Blog';
import MarkdownIt from 'markdown-it';

export const query = graphql`
  query BlogsCategoryQuery($id: Int!) {
    strapiBlogCategories(strapiId: { eq: $id }) {
      strapiId
      Title
      Content
      Key_Visual {
        publicURL
      }
    }
    allStrapiBlogCategories {
      nodes {
        strapiId
        Title
      }
    }
    allStrapiBlogs {
      nodes {
        strapiId
        Title
        Intro
        Content
        Category {
          id
          Title
        }
        Key_Visual {
          publicURL
        }
      }
    }
  }
`;

const Blogs = ({ data, pageContext }) => {
  const blogs = data.allStrapiBlogs.nodes
    .filter(blog => blog.Category && blog.Category.id === pageContext.id)
    .sort((a, b) => (a.created_at < b.created_at ? -1 : 1));

  const blogCategories = data.allStrapiBlogCategories.nodes.map(
    category => category.Title
  );

  const category = data.strapiBlogCategories;

  const navigationData = [];
  blogCategories.forEach(category => {
    navigationData.push({
      title: category,
      to: formatSlug(['blog', category]),
    });
  });

  const keyVisualUrl = category.Key_Visual ? category.Key_Visual.publicURL : '';
  const Markdown = new MarkdownIt({ html: true });
  let content = Markdown.render(category.Content || '');
  content = <div dangerouslySetInnerHTML={{ __html: content }} />;

  return (
    <Layout title={category.Title}>
      <HeroHeader
        title={category.Title}
        backgroundUrl={keyVisualUrl}
        fullWidth
      />
      <Content style={{ marginLeft: '0', margin: '0 auto' }}>
        <h2>{category.Title}</h2>
        {content}
        <BlogContainer>
          {blogs.map(blog => (
            <BlogEntry key={blog.strapiId} blog={blog} />
          ))}
        </BlogContainer>
      </Content>
    </Layout>
  );
};

export default Blogs;
